import * as React from "react";
import { useMemo } from "react";
import { LocalArea, LocalAreaKey, getLocalArea } from "../../config/LocalArea";
import OwnerLpIndex, { OwnerLpIndexProps } from "./OwnerLpIndex";

type Props = {
  areaKey: LocalAreaKey;
};

export function LocalAreaOwnerLpIndex({ areaKey }: Props) {
  const area = useMemo<LocalArea>(() => getLocalArea(areaKey), [areaKey]);
  const props = useMemo<OwnerLpIndexProps>(() => toLpProps(area), [area]);

  return <OwnerLpIndex {...props}>{area.name}</OwnerLpIndex>;
}

const toLpProps = (area: LocalArea): OwnerLpIndexProps => {
  return {
    seo: {
      title: area.title,
      noTitleTemplate: true,
    },
    area,
  };
};
