// 以下の設定内容はスプレッドシートを参照ください。
// https://docs.google.com/spreadsheets/d/11YnAPQ07DVlzaT5Uku7InKk13cLq84QT-f55W_o5BtY/edit#gid=0

//
const LIST_AS_CONST = [
  {
    key: "food-kamakura",
    trackingId: "google_ad_food_kamakura",
    name: "鎌倉",
    title: "ペット同伴可の飲食店を、カンタンに見つけるならWan!Pass（ワンパス）",
    catch1: "わんこと一緒、鎌倉。",
    catch2: "ワンちゃん同伴可の\n飲食店をカンタンに\n見つけるならワンパス！",
    catch2Sp: "ワンちゃん同伴可の飲食店を\nカンタンに見つけるならワンパス！",
  },
  {
    key: "food-karuizawa",
    trackingId: "google_ad_food_karuizawa",
    name: "軽井沢",
    title: "ペット同伴可の飲食店を、カンタンに見つけるならWan!Pass（ワンパス）",
    catch1: "わんこと一緒、軽井沢。",
    catch2: "ワンちゃん同伴可の\n飲食店をカンタンに\n見つけるならワンパス！",
    catch2Sp: "ワンちゃん同伴可の飲食店を\nカンタンに見つけるならワンパス！",
  },
  {
    key: "food-shonan",
    trackingId: "google_ad_food_shonan",
    name: "湘南",
    title: "ペット同伴可の飲食店を、カンタンに見つけるならWan!Pass（ワンパス）",
    catch1: "わんこと一緒、湘南。",
    catch2: "ワンちゃん同伴可の\n飲食店をカンタンに\n見つけるならワンパス！",
    catch2Sp: "ワンちゃん同伴可の飲食店を\nカンタンに見つけるならワンパス！",
  },
  {
    key: "food-yatsugatake",
    trackingId: "google_ad_food_yatsugatake",
    name: "八ヶ岳",
    title: "ペット同伴可の飲食店を、カンタンに見つけるならWan!Pass（ワンパス）",
    catch1: "わんこと一緒、八ヶ岳。",
    catch2: "ワンちゃん同伴可の\n飲食店をカンタンに\n見つけるならワンパス！",
    catch2Sp: "ワンちゃん同伴可の飲食店を\nカンタンに見つけるならワンパス！",
  },
  {
    key: "food-yokohama",
    trackingId: "google_ad_food_yokohama",
    name: "横浜",
    title: "ペット同伴可の飲食店を、カンタンに見つけるならWan!Pass（ワンパス）",
    catch1: "わんこと一緒、横浜。",
    catch2: "ワンちゃん同伴可の\n飲食店をカンタンに\n見つけるならワンパス！",
    catch2Sp: "ワンちゃん同伴可の飲食店を\nカンタンに見つけるならワンパス！",
  },
  {
    key: "hotel-karuizawa",
    trackingId: "google_ad_hotel_karuizawa",
    name: "軽井沢",
    title: "ペット同伴可の宿泊先を、カンタンに見つけるならWan!Pass（ワンパス）",
    catch1: "わんこと一緒、軽井沢。",
    catch2: "ワンちゃん同伴可の\n宿泊先をカンタンに\n見つけるならワンパス！",
    catch2Sp: "ワンちゃん同伴可の宿泊先を\nカンタンに見つけるならワンパス！",
  },
  {
    key: "hotel-yatsugatake",
    trackingId: "google_ad_hotel_yatsugatake",
    name: "八ヶ岳",
    title: "ペット同伴可の宿泊先を、カンタンに見つけるならWan!Pass（ワンパス）",
    catch1: "わんこと一緒、八ヶ岳。",
    catch2: "ワンちゃん同伴可の\n宿泊先をカンタンに\n見つけるならワンパス！",
    catch2Sp: "ワンちゃん同伴可の宿泊先を\nカンタンに見つけるならワンパス！",
  },
  {
    key: "hotel-izu-kamakura",
    trackingId: "google_ad_hotel_izu_kamakura",
    name: "伊豆・鎌倉",
    title: "ペット同伴可の宿泊先を、カンタンに見つけるならWan!Pass（ワンパス）",
    catch1: "わんこと一緒、伊豆・鎌倉。",
    catch2: "ワンちゃん同伴可の\n宿泊先をカンタンに\n見つけるならワンパス！",
    catch2Sp: "ワンちゃん同伴可の宿泊先を\nカンタンに見つけるならワンパス！",
  },
  {
    key: "food-all",
    trackingId: "google_ad_food_all",
    name: "飲食店",
    title: "ペット同伴可の飲食店を、カンタンに見つけるならWan!Pass（ワンパス）",
    catch1: "わんこと一緒、どこまでも。",
    catch2: "ワンちゃん同伴可の\n飲食店をカンタンに\n見つけるならワンパス！",
    catch2Sp: "ワンちゃん同伴可の飲食店を\nカンタンに見つけるならワンパス！",
  },
  {
    key: "hotel-all",
    trackingId: "google_ad_hotel_all",
    name: "宿泊先",
    title: "ペット同伴可の宿泊先を、カンタンに見つけるならWan!Pass（ワンパス）",
    catch1: "わんこと一緒、どこまでも。",
    catch2: "ワンちゃん同伴可の\n宿泊先をカンタンに\n見つけるならワンパス！",
    catch2Sp: "ワンちゃん同伴可の宿泊先を\nカンタンに見つけるならワンパス！",
  },
  {
    key: "passport",
    trackingId: "google_ad_passport",
    name: "生活",
    title: "ペット同伴可の宿泊先を、カンタンに見つけるならWan!Pass（ワンパス）",
    catch1: "わんことの生活をもっと便利に。",
    catch2:
      "ワクチン証明書をデジタル化して\n便利に。\nワンちゃん同伴可のお出かけ先も\n見つかる！",
    catch2Sp:
      "ワクチン証明書をデジタル化して便利に。\nワンちゃん同伴可のお出かけ先も見つかる！",
    viewSpec: {
      isCatch2Small: true,
      isPriorPaper: true,
    },
  },
  // https://docs.google.com/spreadsheets/d/1l1iiTyIN3Du9l5nFxHbEGjllesGPft-m/edit?gid=1838843864#gid=1838843864
  // https://petsallright.slack.com/archives/C03RSU9UV1A/p1732152899785129
  {
    key: "yahoo-search-food",
    trackingId: "yahoo_ad_search_food",
    name: "飲食店",
    title: "ペット同伴可の飲食店を、カンタンに見つけるならWan!Pass（ワンパス）",
    catch1: "わんこと一緒、どこまでも。",
    catch2: "ワンちゃん同伴可の\n飲食店をカンタンに\n見つけるならワンパス！",
    catch2Sp: "ワンちゃん同伴可の飲食店を\nカンタンに見つけるならワンパス！",
  },
  {
    key: "yahoo-display-food",
    trackingId: "yahoo_ad_display_food",
    name: "飲食店",
    title: "ペット同伴可の飲食店を、カンタンに見つけるならWan!Pass（ワンパス）",
    catch1: "わんこと一緒、どこまでも。",
    catch2: "ワンちゃん同伴可の\n飲食店をカンタンに\n見つけるならワンパス！",
    catch2Sp: "ワンちゃん同伴可の飲食店を\nカンタンに見つけるならワンパス！",
  },
  {
    key: "yahoo-search-hotel",
    trackingId: "yahoo_ad_search_hotel",
    name: "宿泊先",
    title: "ペット同伴可の宿泊先を、カンタンに見つけるならWan!Pass（ワンパス）",
    catch1: "わんこと一緒、どこまでも。",
    catch2: "ワンちゃん同伴可の\n宿泊先をカンタンに\n見つけるならワンパス！",
    catch2Sp: "ワンちゃん同伴可の宿泊先を\nカンタンに見つけるならワンパス！",
  },
  {
    key: "yahoo-display-hotel",
    trackingId: "yahoo_ad_display_hotel",
    name: "宿泊先",
    title: "ペット同伴可の宿泊先を、カンタンに見つけるならWan!Pass（ワンパス）",
    catch1: "わんこと一緒、どこまでも。",
    catch2: "ワンちゃん同伴可の\n宿泊先をカンタンに\n見つけるならワンパス！",
    catch2Sp: "ワンちゃん同伴可の宿泊先を\nカンタンに見つけるならワンパス！",
  },
  {
    key: "yahoo-display-passport",
    trackingId: "yahoo_ad_display_passport",
    name: "生活",
    title: "ペット同伴可の宿泊先を、カンタンに見つけるならWan!Pass（ワンパス）",
    catch1: "わんことの生活をもっと便利に。",
    catch2:
      "ワクチン証明書をデジタル化して\n便利に。\nワンちゃん同伴可のお出かけ先も\n見つかる！",
    catch2Sp:
      "ワクチン証明書をデジタル化して便利に。\nワンちゃん同伴可のお出かけ先も見つかる！",
    viewSpec: {
      isCatch2Small: true,
      isPriorPaper: true,
    },
  },
] as const;

export type LocalAreaViewSpec = "catch2PcSmall" | "priorCertificate";

export type LocalAreaKey = typeof LIST_AS_CONST[number]["key"];
export type LocalArea = {
  key: LocalAreaKey;
  name: string;
  title: string;
  catch1: string;
  catch2: string;
  catch2Sp: string;
  trackingId: string;
  viewSpec?: {
    isCatch2Small?: boolean;
    isPriorPaper?: boolean;
  };
};

export const LOCAL_AREAS: readonly LocalArea[] = LIST_AS_CONST.map((props) => ({
  ...props,
}));

export const getLocalArea = (key: LocalAreaKey): LocalArea => {
  const ret = LOCAL_AREAS.find((area) => area.key === key);
  if (!ret) throw new Error(`Invalid error key=${key}`);
  return ret;
};
